import React, {useState} from 'react';
import {string, func, oneOf} from "prop-types";
import Title from "../Title";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CtaPrimary from "../Buttons/Button";
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import {Link} from "gatsby";
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

const specialLabel = () => {
    return (
        <>
            I hereby consent to the processing of the personal data that I have provided and declare my
            agreement with the data protection regulations in the data privacy statement <Link className='text-black-300' to='/privacy-and-cookies-policy'>here</Link>
        </>
    )
}

const ContactForm = (props) => {
    const {className} = props
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    let schema = Yup.object({
        phoneNumber: Yup.string().matches(phoneRegExp, 'This phone doesnt look valid').required('A phone number is required'),
        email: Yup.string().email('Invalid email format').required('Required'),
        content: Yup.string().required('Please leave a message').min(3, 'Your message should be at least 3 characters long'),
    })
    const [state, setState] = useState({
        email: '',
        phoneNumber: '',
        content: '',
    })
    const [checked, setGdprCheck] = useState(false)
    const [errorList, setErrors] = useState({
        email: '',
        phoneNumber: '',
        content: '',
    })
    const handleChange = (e) => {
        const target = e.target
        const name = target.name
        const value = target.value

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleCheck = () => {
        setGdprCheck((prevState => !prevState))
    }
    const handleSubmit = (event) => {
        setErrors({
            email: '',
            phoneNumber: '',
            content: '',
        })

        schema.validate({
            phoneNumber: state.phoneNumber,
            email: state.email,
            content: state.content,
        }, {abortEarly: false}).then(() => {
            if (!checked) {
                toast.warn('Please agree to the GDPR terms', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "contact-us", ...state, "gdprCheck": "true" })
                }).then(() => {
                    toast.success('🦄 Submitted Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setState({
                        email: '',
                        phoneNumber: '',
                        content: '',
                    })
                })
            }
        }).catch((err) => {
            err.inner.forEach(e => {
                setErrors(prevState => ({
                    ...prevState,
                    [e.path]: e.message
                }))
            });
        })

    }
    return (
        <form name='contact-us' data-netlify="true"  className='bg-black-800 py-[80px] contact-form'>
            <input hidden name='form-name' onChange={() => null} value='contact-us'/>
            <Title level={2} withBlueSymbol symbol='.' className='text-center text-black  text-[42px] leading-[140%]'>Contact form</Title>
            <div className='grid md:grid-cols-2 grid-cols-1 md:gap-24 gap-12 xl:px-[256px] md:mx-8 mx-[16px] max-w-[1440px] xl:mx-auto'>
                <TextField
                    name='email'
                    label="Email"
                    variant="standard"
                    className='font-sans'
                    value={state.email}
                    onChange={handleChange}
                    error={!!errorList.email}
                    helperText={errorList.email}
                />
                <TextField
                    name='phoneNumber'
                    label="Phone Number"
                    variant="standard"
                    value={state.phoneNumber}
                    onChange={handleChange}
                    error={!!errorList.phoneNumber}
                    helperText={errorList.phoneNumber}
                />
            </div>
            <div className='gap-24 mx-[16px] md:mx-8 mt-4 max-w-[1440px] xl:px-[256px] xl:mx-auto'>
                <TextField
                    id="filled-multiline-flexible"
                    label="Your message"
                    multiline
                    minRows={4}
                    maxRows={12}
                    variant="standard"
                    style={{ width: '100%' }}
                    name='content'
                    value={state.content}
                    onChange={handleChange}
                    error={!!errorList.content}
                    helperText={errorList.content}
                />
            </div>
                <FormGroup className='w-full grid grid-cols-1 mt-8'>
                    <div className='md:mx-8 max-w-[1440px] xl:px-[256px] mx-[16px] xl:mx-auto'>
                        <FormControlLabel
                            className='font-sans max-w-[100%]'
                            control={<Checkbox  name='gdprCheck' checked={checked} onChange={handleCheck}/>}
                            label={specialLabel()}
                        />
                    </div>
                </FormGroup>
            <div className='max-w-[100%]'>
                <CtaPrimary className='border-box md:mx-auto my-10 md:px-8 px-[35%] md:w-auto w-[90%]  mx-[16px]' title='Send' onClick={handleSubmit} />
            </div>
        </form>
    );
};
ContactForm.propTypes = {
    className: string,
    handleSubmit: func,
};

ContactForm.defaultProps = {
    className: '',
    handleSubmit: () => null,
};

export default ContactForm;
